<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Account Validation</h1>
            <b-form @submit="verifyUserIdentity" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                Your validation code is invalid, please try again.
              </div>

              <b-form-group label-for="code" class="my-2">
                <p class="text-left text-muted small p-0 m-0 pb-2 px-1" v-if="$props.verificationMethod === 'EMAIL'">A validation code has been sent to your email address.</p>
                <p class="text-left text-muted small p-0 m-0 pb-2 px-1" v-if="$props.verificationMethod === 'SMS'">A validation code has been sent to the mobile number ending in <strong>{{$props.phoneLastFourDigits}}</strong>.</p>

                <b-input-group>
                  <b-form-input v-model="$v.input.verificationCode.$model" type="text" placeholder="Validation PIN" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="submitted && $v.input.verificationCode.$invalid">
                  <span v-if="!$v.input.verificationCode.required">Validation PIN is required</span>
                </div>
              </b-form-group>

              <b-form-group label-for="remember-me" class="mb-1">
                <b-input-group class="pl-1">
                  <div class="py-2 small w-100 text-left">Would you like us to remember this device?</div>
                  <b-form-checkbox v-model="input.generateAgentId"></b-form-checkbox>
                  <label class="text-muted pt-1">Yes, I am on a trusted device / computer.</label>
                </b-input-group>
              </b-form-group>

              <b-btn :disabled="isSubmitting" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Validate</span>
                <div v-if="isSubmitting" v-bind:class="{'local-spinner-border':isSubmitting, 'local-spinner-spacer':!isSubmitting}"/>
              </b-btn>
            </b-form>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application'
import FormMixin from '@/mixins/formMixin'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'verifyIdentity',
  mixins: [FormMixin],
  props: ['identityId', 'verificationMethod', 'phoneLastFourDigits'],
  data: function() {
    return {
      input: {
        verificationCode: '',
        generateAgentId: false
      },
      timeout: null,
      isSubmitting: false
    }
  },
  validations: {
    input: {
      verificationCode: {
        required
      }
    }
  },
  methods: {
    onTimeout: function() {
      this.isSubmitting = false;
    },

    verifyUserIdentity: function() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      this.timeout = setTimeout(this.onTimeout, 5000);

      this.$root.trustDevice = false;

      this.submit('patch', ApiProperties.api.verifyUserIdentity, { pathVars: { identityId: this.$props.identityId }}, this.input, (response1) => {

            const requestObj = {
              identityClaimToken: response1.data.identityClaimToken,
              generateAgentId: this.input.generateAgentId
            };

            this.submit('post', ApiProperties.api.createVerifiedUser, { pathVars: { identityId: this.$props.identityId }}, requestObj, (response2) => {
                  this.$root.updateAuthStatus(response2.data);
                },
                () => { // error in initialize verified user
                  this.isSubmitting = false;
                }
            )
          },
          () => { // error in verify user identity
            this.isSubmitting = false;
          }
      )
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  }
}
</script>
<style lang="scss">
@import "../scss/login-pages";

</style>
